import axios from 'axios';

// Update the proxy URL to use the environment variable
const corsProxy = `${process.env.REACT_APP_API_URL}/proxy?url=`;
const cache = new Map();

const fetchWithRetry = async (url, options, retries = 3) => {
  try {
    return await axios.get(url, options);
  } catch (error) {
    if (retries > 0) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      return fetchWithRetry(url, options, retries - 1);
    }
    throw error;
  }
};

export const fetchChapterData = async (chapterUrl, metadataOnly = false) => {
  if (cache.has(chapterUrl)) {
    return cache.get(chapterUrl);
  }

  try {
    const response = await fetchWithRetry(`${corsProxy}${encodeURIComponent(chapterUrl)}`, {
      responseType: 'text'
    });
    const htmlContent = response.data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    
    const chapterHeading = doc.querySelector('h1#chapter-heading');
    const heading = chapterHeading ? chapterHeading.textContent.trim() : '';

    if (metadataOnly) {
      return { heading };
    }

    const imgElements = doc.querySelectorAll('img.wp-manga-chapter-img');
    const imageUrls = Array.from(imgElements).map((img) => img.getAttribute('data-src') || img.src);

    const chapterData = { heading, imageUrls };
    cache.set(chapterUrl, chapterData);

    return chapterData;
  } catch (error) {
    console.error('Error fetching chapter content:', error);
    return { heading: '', imageUrls: [] };
  }
};

export const fetchAvailableChapters = async (baseUrl) => {
  try {
    const response = await fetchWithRetry(`${corsProxy}${encodeURIComponent(baseUrl)}`, {
      responseType: 'text'
    });
    const htmlContent = response.data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const chapterLinks = doc.querySelectorAll('.wp-manga-chapter a');
    const chapters = Array.from(chapterLinks).map((link) => {
      const chapterMatch = link.href.match(/chapter-([^/]+)/);
      const chapterNumber = chapterMatch ? chapterMatch[1] : link.textContent.trim().replace('Chapter ', '');
      return {
        url: link.href,
        name: link.textContent.trim(),
        number: chapterNumber
      };
    }).reverse(); 
    return chapters;
  } catch (error) {
    console.error('Error fetching available chapters:', error);
    return [];
  }
};

export const fetchTrendingManga = async () => {
  const trendingUrl = 'https://www.mangaread.org/manga/?m_orderby=trending';
  
  if (cache.has(trendingUrl)) {
    return cache.get(trendingUrl);
  }

  try {
    const response = await fetchWithRetry(`${corsProxy}${encodeURIComponent(trendingUrl)}`, {
      responseType: 'text'
    });
    const htmlContent = response.data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const mangaLinks = doc.querySelectorAll('.post-title a');
    const trendingManga = Array.from(mangaLinks)
      .filter((link) => !link.href.includes('chapter'))
      .map((link) => ({
        title: link.textContent.trim(),
        url: link.href,
      }));
    
    cache.set(trendingUrl, trendingManga);
    return trendingManga;
  } catch (error) {
    console.error('Error fetching trending manga:', error);
    return [];
  }
};

export const preloadNextChapter = async (currentChapterUrl, availableChapters) => {
  const currentChapterMatch = currentChapterUrl.match(/chapter-(\d+)/);
  if (currentChapterMatch && currentChapterMatch[1]) {
    const currentChapterNumber = parseInt(currentChapterMatch[1], 10);
    const nextChapterNumber = currentChapterNumber + 1;
    if (availableChapters.includes(nextChapterNumber)) {
      const nextChapterUrl = currentChapterUrl.replace(`chapter-${currentChapterNumber}`, `chapter-${nextChapterNumber}`);
      await fetchChapterData(nextChapterUrl);
    }
  }
};

export const searchManga = async (query) => {
  const searchUrl = `https://www.mangaread.org/?s=${encodeURIComponent(query)}&post_type=wp-manga`;

  try {
    const response = await axios.get(`${corsProxy}${encodeURIComponent(searchUrl)}`, {
      responseType: 'text'
    });
    const htmlContent = response.data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const searchResults = doc.querySelectorAll('.c-tabs-item__content');

    return Array.from(searchResults).map(result => {
      const titleElement = result.querySelector('.post-title h3 a');
      const descriptionElement = result.querySelector('.post-content_item.mg_description .summary-content');
      return {
        title: titleElement ? titleElement.textContent.trim() : '',
        url: titleElement ? titleElement.href : '',
        description: descriptionElement ? descriptionElement.textContent.trim() : ''
      };
    });
  } catch (error) {
    console.error('Error searching manga:', error);
    throw error;
  }
};
