import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './css/App.css';
import HomePage from './HomePage';
import ViewPage from './ViewPage';
import LoginPage from './LoginPage';
import SearchPage from './SearchPage';
import ErrorBoundary from './ErrorBoundary';
import PrivacyPolicy from './components/LegalPages/PrivacyPolicy';
import TermsOfService from './components/LegalPages/TermsOfService';
import { AuthProvider } from './context/AuthContext';
import MainNav from './components/MainNav';

function App() {
  const getMangaBaseUrl = () => {
    // Your existing getMangaBaseUrl logic
  };

  return (
    <AuthProvider>
      <ErrorBoundary>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Router>
            <div className="App">
              <header className="App-header">
                <img src="/ML_Transparent.png" alt="ML Logo" className="app-logo" />
                <MainNav />
              </header>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route 
                  path="/view/:chapterNumber" 
                  element={<ViewPage getMangaBaseUrl={getMangaBaseUrl} />} 
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>
            </div>
          </Router>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </AuthProvider>
  );
}

export default App;
