import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { useAuth } from './context/AuthContext';
import authAxios from './utils/authAxios';
import './css/LoginPage.css';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login: authLogin } = useAuth(); // Renamed to authLogin
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSuccess = async (tokenResponse) => {
    console.log('Google Sign-In successful', tokenResponse);
    setIsLoading(true);
    setError('');

    try {
      console.log('Sending token to backend');
      console.log('Current environment:', process.env.REACT_APP_ENV);
      const response = await authAxios.post('/auth-google', {
        token: tokenResponse.access_token
      });

      console.log('Backend response:', response.data);
      const { user } = response.data;

      authLogin(user);
      navigate('/');
    } catch (error) {
      console.error('Authentication failed:', error);
      if (error.code === 'ECONNABORTED') {
        setError('Server request timed out. Please try again.');
      } else if (error.response) {
        setError(`Authentication failed: ${error.response.data.message || 'Unknown error'}`);
      } else if (error.request) {
        setError(`Connection error: ${error.message}`);
      } else {
        setError(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginError = (errorResponse) => {
    console.error('Login Failed:', errorResponse);
    setError('Login failed. Please try again.');
    setIsLoading(false);
  };

  const googleLogin = useGoogleLogin({ // Renamed from login to googleLogin
    onSuccess: handleLoginSuccess,
    onError: handleLoginError
  });

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-description">
          <p>Sign in to unlock enhanced features:</p>
          <ul>
            <li>Track your reading progress</li>
            <li>Save your favorite manga</li>
            <li>Sync settings across devices</li>
            <li>Resume where you left off</li>
          </ul>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button 
          className="google-login-button"
          onClick={() => {
            console.log('Login button clicked');
            setIsLoading(true);
            try {
              googleLogin(); // Use renamed function
            } catch (error) {
              console.error('Login error:', error);
              setIsLoading(false);
              setError('Failed to initialize login. Please try again.');
            }
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <span>Signing in...</span>
          ) : (
            <>
              <FaGoogle className="google-icon" />
              <span>Sign in with Google</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
