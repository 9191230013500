import { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import authAxios from '../utils/authAxios';

export const useBookmarkManager = (mangaBaseUrl, currentChapter, mangaTitle) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const { user } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);
  const isSyncingRef = useRef(false);

  const syncBookmarks = useCallback(async () => {
    if (!user || isSyncingRef.current) return;
    try {
      isSyncingRef.current = true;
      
      // Get server bookmarks
      const response = await authAxios.get('/bookmarks', {
        headers: {
          'x-user-id': user.id
        },
        timeout: 10000
      });

      const serverBookmarks = response.data.map(item => ({
        title: item.manga_title,
        chapter: item.chapter,
        mangaBaseUrl: item.manga_base_url,
        timestamp: item.timestamp
      }));

      // Get local bookmarks
      const localBookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');

      // Create map for comparison
      const serverMap = new Map(serverBookmarks.map(b => [b.mangaBaseUrl + b.chapter, b]));

      // Find bookmarks that need to be synced to server
      const localOnlyBookmarks = localBookmarks.filter(bookmark => {
        const key = bookmark.mangaBaseUrl + bookmark.chapter;
        return !serverMap.has(key);
      });

      // Sync local-only bookmarks to server
      for (const bookmark of localOnlyBookmarks) {
        try {
          await authAxios.post('/bookmarks', {
            mangaTitle: bookmark.title,
            chapter: bookmark.chapter,
            mangaBaseUrl: bookmark.mangaBaseUrl,
            timestamp: bookmark.timestamp,
            isBookmarked: true
          }, {
            headers: {
              'x-user-id': user.id
            }
          });
          console.log('Successfully synced bookmark to server');
        } catch (error) {
          console.error('Failed to sync local bookmark to server:', error);
        }
      }

      // Merge all bookmarks, preferring server timestamps if they exist
      const mergedBookmarks = [
        ...localBookmarks.filter(bookmark => {
          const key = bookmark.mangaBaseUrl + bookmark.chapter;
          return !serverMap.has(key);
        }),
        ...serverBookmarks
      ];

      // Update localStorage with merged bookmarks
      localStorage.setItem('bookmarks', JSON.stringify(mergedBookmarks));
      
      // Trigger storage event for other tabs
      window.dispatchEvent(new Event('storage'));
      
      // Trigger custom event for current tab
      window.dispatchEvent(new Event('bookmarksUpdated'));
      
      return mergedBookmarks;
    } catch (error) {
      console.error('Failed to sync bookmarks:', error);
      return null;
    } finally {
      isSyncingRef.current = false;
    }
  }, [user]);

  useEffect(() => {
    const loadBookmarkState = async () => {
      if (!mangaBaseUrl || !currentChapter) return;

      const bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
      
      if (bookmarks.length === 0 && user && !isSyncingRef.current) {
        await syncBookmarks();
      }

      const isBookmarkedLocally = bookmarks.some(b => 
        b.mangaBaseUrl === mangaBaseUrl && 
        b.chapter === currentChapter.number
      );
      setIsBookmarked(isBookmarkedLocally);
      setIsInitialized(true);
    };

    loadBookmarkState();
  }, [mangaBaseUrl, currentChapter, user, syncBookmarks]);

  const toggleBookmark = async () => {
    if (!currentChapter || !isInitialized) return;
    
    const newBookmarkState = !isBookmarked;
    setIsBookmarked(newBookmarkState);

    // Get current bookmarks
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
    
    if (newBookmarkState) {
      // Add new bookmark
      const bookmarkData = {
        title: mangaTitle,
        chapter: currentChapter.number,
        mangaBaseUrl,
        timestamp: Date.now()
      };
      
      // Remove any existing bookmark for this manga/chapter if it exists
      const updatedBookmarks = bookmarks.filter(b => 
        !(b.mangaBaseUrl === mangaBaseUrl && b.chapter === currentChapter.number)
      );
      
      // Add the new bookmark
      updatedBookmarks.push(bookmarkData);
      localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
    } else {
      // Remove only this specific bookmark
      const updatedBookmarks = bookmarks.filter(b => 
        !(b.mangaBaseUrl === mangaBaseUrl && b.chapter === currentChapter.number)
      );
      localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
    }

    // Sync with server if user is logged in
    if (user) {
      try {
        await authAxios.post('/bookmarks', {
          mangaTitle,
          chapter: currentChapter.number,
          mangaBaseUrl,
          timestamp: Date.now(),
          isBookmarked: newBookmarkState
        }, {
          headers: {
            'x-user-id': user.id
          }
        });
      } catch (error) {
        console.error('Failed to sync bookmark:', error);
      }
    }

    // Trigger storage event for HomePage to update
    window.dispatchEvent(new Event('storage'));
  };

  return {
    isBookmarked,
    toggleBookmark,
    isInitialized,
    syncBookmarks
  };
}; 