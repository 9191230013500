import React from 'react';
import { FaBookmark, FaRegBookmark, FaShareAlt, FaArrowLeft, FaArrowRight, FaArrowUp } from 'react-icons/fa';
import LazyLoad from 'react-lazyload';

export const ChapterHeader = ({ title, isBookmarked, onBookmark, onShare }) => (
  <header className="chapter-header">
    <div className="chapter-title-container">
      <h1 className="chapter-title">{title}</h1>
    </div>
    <div className="chapter-actions">
      <button 
        className={`bookmark-button ${isBookmarked ? 'bookmarked' : ''}`} 
        onClick={onBookmark}
        aria-label={isBookmarked ? 'Bookmarked' : 'Bookmark'}
      >
        {isBookmarked ? <FaBookmark /> : <FaRegBookmark />}
      </button>
      <button 
        className="share-button"
        onClick={onShare}
        aria-label="Share"
      >
        <FaShareAlt />
      </button>
    </div>
  </header>
);

export const UrlInput = ({ chapterUrl, onUrlChange }) => (
  <div className="url-input-container">
    <input
      className="url-input"
      type="text"
      value={chapterUrl}
      onChange={onUrlChange}
      placeholder="Enter chapter URL"
    />
  </div>
);

export const ChapterNavigation = ({ chapter, availableChapters, onPreviousChapter, onNextChapter, onChapterSelect }) => (
  <nav className="chapter-navigation">
    <button
      className="nav-button prev-chapter"
      onClick={onPreviousChapter}
      disabled={!chapter || availableChapters.findIndex(ch => ch.number === chapter) === 0}
      aria-label="Previous Chapter"
    >
      <FaArrowLeft className="button-icon" />
      <span className="button-text">Previous</span>
    </button>
    <select
      className="chapter-dropdown"
      value={chapter || ''}
      onChange={onChapterSelect}
    >
      {availableChapters.map(ch => (
        <option key={ch.number} value={ch.number}>
          Chapter {ch.number}
        </option>
      ))}
    </select>
    <button
      className="nav-button next-chapter"
      onClick={onNextChapter}
      disabled={!chapter || availableChapters.findIndex(ch => ch.number === chapter) === availableChapters.length - 1}
      aria-label="Next Chapter"
    >
      <span className="button-text">Next</span>
      <FaArrowRight className="button-icon" />
    </button>
  </nav>
);

export const MangaImageViewer = ({ images }) => (
  <div className="manga-images-container">
    {images.map((imgSrc, index) => (
      <div key={index} className="image-wrapper">
        {imgSrc ? (
          <LazyLoad height={200} offset={100} once>
            <img 
              src={imgSrc} 
              alt={`Page ${index + 1}`} 
              className="manga-image"
            />
          </LazyLoad>
        ) : (
          <div className="image-placeholder">Loading...</div>
        )}
      </div>
    ))}
  </div>
);

export const LoadingSpinner = () => (
  <div className="loading-container">
    <div className="loading-spinner"></div>
  </div>
);

export const BottomNavigation = ({ onPreviousChapter, onNextChapter, onBackToTop, isPreviousDisabled, isNextDisabled }) => (
  <nav className="bottom-navigation">
    <button
      className="nav-button prev-chapter"
      onClick={onPreviousChapter}
      disabled={isPreviousDisabled}
      aria-label="Previous Chapter"
    >
      <FaArrowLeft className="button-icon" />
      <span className="button-text">Previous</span>
    </button>
    <button
      className="nav-button back-to-top"
      onClick={onBackToTop}
      aria-label="Back to Top"
    >
      <FaArrowUp className="button-icon" />
      <span className="button-text">Top</span>
    </button>
    <button
      className="nav-button next-chapter"
      onClick={onNextChapter}
      disabled={isNextDisabled}
      aria-label="Next Chapter"
    >
      <span className="button-text">Next</span>
      <FaArrowRight className="button-icon" />
    </button>
  </nav>
);
