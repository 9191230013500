import React from 'react';
import './LegalPages.css';
import LegalLayout from './LegalLayout';

const TermsOfService = () => {
  return (
    <LegalLayout>
      <div className="legal-container">
        <h1>Terms of Service</h1>
        <p className="last-updated">Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using Manga: Library of Legends ("we," "our," or "us"), you agree to be bound by these Terms of Service.</p>
        </section>

        <section>
          <h2>2. User Responsibilities</h2>
          <ul>
            <li>You must be at least 13 years old to use this service</li>
            <li>You are responsible for maintaining the confidentiality of your account</li>
            <li>You agree not to use the service for any illegal purposes</li>
            <li>You will not attempt to circumvent any security features</li>
          </ul>
        </section>

        <section>
          <h2>3. Content Usage</h2>
          <p>All content available through our service is for personal, non-commercial use only. You may not distribute, modify, or use the content for any commercial purpose.</p>
        </section>

        <section>
          <h2>4. Service Availability</h2>
          <p>We strive to provide uninterrupted service but do not guarantee continuous access to our service. We reserve the right to modify or discontinue the service at any time.</p>
        </section>

        <section>
          <h2>5. User Accounts</h2>
          <p>To access certain features, you may need to create an account. You are responsible for:</p>
          <ul>
            <li>Providing accurate account information</li>
            <li>Maintaining the security of your account</li>
            <li>All activities that occur under your account</li>
          </ul>
        </section>

        <section>
          <h2>6. Intellectual Property</h2>
          <p>The service and its original content are protected by copyright, trademark, and other laws. Our trademarks and trade dress may not be used without our prior written permission.</p>
        </section>

        <section>
          <h2>7. Limitation of Liability</h2>
          <p>We shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use the service.</p>
        </section>

        <section>
          <h2>8. Changes to Terms</h2>
          <p>We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through the service.</p>
        </section>

        <section>
          <h2>9. Contact Information</h2>
          <p>For any questions about these Terms, please contact us at:</p>
          <p><a href="mailto:support@manga.lol">support@manga.lol</a></p>
        </section>

        <section>
          <h2>10. DMCA Compliance</h2>
          <p>We respect intellectual property rights and comply with the Digital Millennium Copyright Act (DMCA). If you believe your work has been copied in a way that constitutes copyright infringement, please contact us.</p>
        </section>

        <section>
          <h2>11. Dispute Resolution</h2>
          <p>Any disputes arising from these Terms will be governed by the laws of [Your Jurisdiction]. You agree to submit to the personal jurisdiction of the courts located within [Your Jurisdiction].</p>
        </section>

        <div className="important-notice">
          <h3>Important Notice</h3>
          <p>By using our service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
        </div>

        <div className="contact-section">
          <h2>Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <p><a href="mailto:support@manga.lol">support@manga.lol</a></p>
        </div>
      </div>
    </LegalLayout>
  );
};

export default TermsOfService;
