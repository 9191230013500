import { useState, useEffect, useCallback, useRef } from 'react';
import authAxios from '../utils/authAxios';

export const useReadingProgress = (user) => {
  const [isLoading, setIsLoading] = useState(false);
  const syncTimeoutRef = useRef(null);
  const abortControllerRef = useRef(null);

  // Debounced sync function
  const debouncedSync = useCallback(async (progressData) => {
    if (syncTimeoutRef.current) {
      clearTimeout(syncTimeoutRef.current);
    }

    syncTimeoutRef.current = setTimeout(async () => {
      if (!user) return;

      try {
        await authAxios.post('/user-progress', {
          mangaTitle: progressData.mangaTitle,
          chapter: progressData.chapter,
          mangaBaseUrl: progressData.mangaBaseUrl,
          timestamp: progressData.timestamp
        }, {
          headers: {
            'x-user-id': user.id
          },
          timeout: 5000 // Reduced timeout
        });
      } catch (error) {
        console.error('Failed to sync progress:', error);
      }
    }, 2000);
  }, [user]);

  // Sync progress function with local storage update and debounced server sync
  const syncProgress = useCallback(async (progressData) => {
    // Update localStorage immediately
    try {
      const savedProgress = JSON.parse(localStorage.getItem('readingProgress') || '{}');
      savedProgress[progressData.mangaBaseUrl] = {
        mangaTitle: progressData.mangaTitle,
        chapter: progressData.chapter,
        timestamp: progressData.timestamp
      };
      localStorage.setItem('readingProgress', JSON.stringify(savedProgress));
      window.dispatchEvent(new Event('progressUpdated'));

      // Sync with server in background
      if (user) {
        debouncedSync(progressData);
      }
    } catch (error) {
      console.error('Error updating local storage:', error);
    }
  }, [user, debouncedSync]);

  // Fetch user progress from server on login
  useEffect(() => {
    let isMounted = true;
    
    const fetchUserProgress = async () => {
      if (!user) return;

      // Cancel any existing request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      try {
        setIsLoading(true);
        const response = await authAxios.get('/user-progress', {
          headers: {
            'x-user-id': user.id
          },
          signal: abortControllerRef.current.signal,
          timeout: 5000 // Reduced timeout
        });

        if (!isMounted) return;

        const savedProgress = JSON.parse(localStorage.getItem('readingProgress') || '{}');
        let progressUpdated = false;

        response.data.forEach(serverItem => {
          const localProgress = savedProgress[serverItem.manga_base_url];
          const serverChapter = Number(serverItem.last_chapter);
          const localChapter = localProgress ? Number(localProgress.chapter) : 0;

          if (!localProgress || serverChapter > localChapter) {
            savedProgress[serverItem.manga_base_url] = {
              mangaTitle: serverItem.manga_title,
              chapter: serverItem.last_chapter,
              timestamp: new Date(serverItem.last_read_timestamp).getTime()
            };
            progressUpdated = true;
          }
        });

        if (progressUpdated) {
          localStorage.setItem('readingProgress', JSON.stringify(savedProgress));
          window.dispatchEvent(new Event('progressUpdated'));
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Progress fetch aborted');
        } else {
          console.error('Error fetching user progress:', error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchUserProgress();

    return () => {
      isMounted = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
    };
  }, [user]);

  return { isLoading, syncProgress };
};
