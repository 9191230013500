import React from 'react';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const LegalLayout = ({ children }) => {
  return (
    <div className="legal-wrapper">
      <div className="legal-nav">
        <Link to="/" className="home-link">
          <FaHome /> Back to Home
        </Link>
      </div>
      <div className="legal-page">
        <div className="legal-container">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LegalLayout;
