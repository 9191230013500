import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../css/MainNav.css';

const MainNav = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const getUserInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  return (
    <nav className="main-nav">
      <div className="nav-left"></div>
      <div className="nav-center">
        <button 
          onClick={() => navigate('/')} 
          className="nav-button"
        >
          Home
        </button>
      </div>
      <div className="nav-right">
        {user ? (
          <>
            <div className="user-avatar">
              {getUserInitial(user.name)}
            </div>
            <button onClick={logout} className="logout-button">
              Logout
            </button>
          </>
        ) : (
          <button onClick={() => navigate('/login')} className="logout-button">
            Login
          </button>
        )}
      </div>
    </nav>
  );
};

export default MainNav;
