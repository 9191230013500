import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-links">
          <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
        </div>
        <div className="footer-contact">
          <a href="mailto:support@manga.lol" className="footer-link">support@manga.lol</a>
        </div>
        <div className="footer-copyright">
          © {new Date().getFullYear()} Library of Legends. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
