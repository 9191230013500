import React from 'react';
import './LegalPages.css';
import LegalLayout from './LegalLayout';

const PrivacyPolicy = () => {
  return (
    <LegalLayout>
      <div className="legal-container">
        <h1>Privacy Policy</h1>
        <p className="last-updated">Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>1. Introduction</h2>
          <p>Welcome to Manga: Library of Legends ("we," "our," or "us"). This Privacy Policy explains how we collect, use, and protect your personal information when you use our web application.</p>
        </section>

        {/* Continue with other sections, formatted as React components */}
        <section>
          <h2>2. Information We Collect</h2>
          <h3>2.1 Personal Information</h3>
          <ul>
            <li>
              Google account information (when you sign in): Name, Email address
            </li>
            <li>Reading preferences and history</li>
            <li>Bookmarks and saved content</li>
          </ul>
          
          <h3>2.2 Automatically Collected Information</h3>
          <ul>
            <li>Browser type and version</li>
            <li>Device information</li>
            <li>IP address</li>
            <li>Usage patterns and reading history</li>
            <li>Cookies and local storage data</li>
          </ul>
        </section>

        <section>
          <h2>3. Data Protection Rights</h2>
          <p>Under GDPR and CCPA, you have the following rights:</p>
          <ul>
            <li>Right to access your personal data</li>
            <li>Right to rectification of inaccurate data</li>
            <li>Right to erasure ("right to be forgotten")</li>
            <li>Right to data portability</li>
            <li>Right to withdraw consent</li>
          </ul>
        </section>

        <section>
          <h2>4. Children's Privacy</h2>
          <p>Our service is not intended for children under 13. We do not knowingly collect personal information from children under 13.</p>
        </section>

        <section>
          <h2>5. International Data Transfers</h2>
          <p>Your information may be transferred to and processed in countries other than your country of residence. We ensure appropriate safeguards are in place for such transfers.</p>
        </section>

        <div className="contact-section">
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p><a href="mailto:support@manga.lol">support@manga.lol</a></p>
        </div>
      </div>
    </LegalLayout>
  );
};

export default PrivacyPolicy;
