import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchManga } from './api';
import { FaSearch, FaBook } from 'react-icons/fa';

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search).get('q');
    if (searchQuery) {
      setSearchTerm(searchQuery);
      performSearch(searchQuery);
    }
  }, [location.search]);

  const performSearch = (query) => {
    setLoading(true);
    setError(''); // Clear any previous errors when starting a new search
    searchManga(query)
      .then(results => {
        setSearchResults(results);
        setError(''); // Ensure error is cleared on success
        setLoading(false);
      })
      .catch(err => {
        console.error('Error searching manga:', err);
        setError('Failed to load search results. Please try again.');
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
      performSearch(searchTerm);
    }
  };

  const formatMangaUrl = (url) => {
    if (url.startsWith('https://www.mangaread.org/manga/')) {
      return `h1/${url.replace('https://www.mangaread.org/manga/', '')}`;
    }
    return url;
  };

  const getFirstChapter = (manga) => {
    return manga.firstChapter || '1';  // Default to '1' if firstChapter is undefined
  };

  return (
    <div className="content">
      <form onSubmit={handleSubmit} className="search-form">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search manga..."
          className="search-input"
        />
        <button type="submit" className="search-button">
          <FaSearch />
        </button>
      </form>

      <h2>Search Results for "{searchTerm}"</h2>
      {loading ? (
        <div className="loading-spinner"></div>
      ) : (
        <>
          {error && searchResults.length === 0 && <div className="error-message">{error}</div>}
          {!error && searchResults.length === 0 ? (
            <p>No results found.</p>
          ) : (
            <div className="search-results-list">
              {searchResults.map((manga, index) => (
                <div 
                  key={index}
                  className="manga-card"
                  onClick={() => navigate(`/view/${getFirstChapter(manga)}?manga=${formatMangaUrl(manga.url)}`)}
                >
                  <div className="manga-title-wrapper">
                    <FaBook className="manga-icon" />
                    <h3 className="manga-title">{manga.title}</h3>
                  </div>
                  <p>{manga.description}</p>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchPage;
