import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:7071/api';

const authAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  maxRedirects: 0,
  timeout: 6000 
});

authAxios.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      console.error('Response error:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('Request error:', error.message);
    } else {
      console.error('Error:', error.message);
    }
    
    if (error.response?.status === 401) {
      window.dispatchEvent(new CustomEvent('unauthorized'));
    }
    return Promise.reject(error);
  }
);

export default authAxios;
